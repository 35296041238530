import $ from 'jquery';

$.fn.exampleSlider = function() {

    var jqAllAffectedContainers = this;

    return jqAllAffectedContainers.each(function() {
        var jqContainer = $(this);
        var sliderContainer = jqContainer.find('.common-slider-container');
        if (!sliderContainer || sliderContainer === undefined) {
            console.error('common-slider: no common-slider-container fround in', this);
        }
        var prev = jqContainer.find('.prev');
        var next = jqContainer.find('.next');
        var nav = jqContainer.find('.nav');
        sliderContainer.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            infinite: false,
            prevArrow: prev,
            nextArrow: next,
            appendDots: nav,
            autoplay: false,
            speed: 500,
            cssEase: 'linear',
            rows: 1,
            responsive: [
                {
                    breakpoint: 1469,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        });
    });

};

document.addEventListener('DOMContentLoaded', function() {

    $('.work-examples-block').exampleSlider();

});
